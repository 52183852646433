import React, { useState, useRef, useEffect } from "react";
import { Button, Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import AWS from "aws-sdk";
import { APIGateway } from "aws-sdk";

import CreateWorkspace from "./CreateWorkspace";
import TerminateWorkspace from "./TerminateWorkspace";
import OpenWorkspace from "./MetadataWorkspace";
import {Table} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//import { useHistory } from 'react-router-dom';

export const ExternalApiComponent = () => {
  const { apiOrigin = "http://localhost:3001", audience } = getConfig();
  const {user} = useAuth0();
  const API_KEY = process.env.REACT_APP_API_KEY;
  const stage = process.env.REACT_APP_STAGE;
  const base_url = process.env.REACT_APP_BASE_URL;
  console.log(stage);
  console.log(base_url);
  const [data, setData] = useState([]);
  //const [data2, setData2] = useState([]);

  //const history = useHistory();
  // Event function that is ifred when click on Launch.
  const handleLaunchClick = async (rowIndex) => {

    if(data.completed_training === true){
        confirmAlert({
          title: "",
          message: (
            <>
              Are you sure you wish to launch an instance?<br />
              You will be charged $0.18/hour until the instance is terminated.
            </>
          ),
          buttons: [
            {
              label: "Launch",
              onClick: async () => {
                const payload = {
                  "email": user.email,
                  "project_id": rowIndex
                };
                const urlLaunch = `${base_url}${stage}/launch`;
                try {
                  const responseLaunch = await fetch(urlLaunch, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Api-Key': API_KEY,
                    },
                    body: JSON.stringify(payload),
                  });
                  if(responseLaunch.ok){
                    setTimeout(() => {
                      alert("Workspace is launching!");
                    }, 5000);
                  }
              } catch (error) {
                console.log("error 2")
                console.log('Error fetching updated workspaces:', error);
              }
              },
            },
          ],
        });
    }/*else{
      confirmAlert({
        title: "",
        message: (
          <>
            You need to complete your training<br />
            before accessing the workspace environment.
          </>
        ),
        buttons: [
          {
            label: "Training",
            onClick: () => {
              history.push('/training');
            }
          }
        ]
      });
    };*/

  };

  // HandleOpenClick function to handle open click
  const handleOpenClick = (rowIndex, instanceId, dns) => {
      window.open(`http://${dns}`, '_blank');
      console.log(instanceId);
  }

  const handleTerminateClick = async (rowIndex, instanceId) => {
    const payload = {
      "instance_id": instanceId,
      "user_email": user.email.toLowerCase(),
    };
    const urlTerminate = `${base_url}${stage}/terminate`;

    try {

      const responseTerminate = await fetch(urlTerminate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': API_KEY,
        },
        body: JSON.stringify(payload),
      });
      if(responseTerminate.ok){
        setTimeout(() => {
          alert("Workspace is terminating!");
        }, 5000);
      }
    } catch (error) {
      console.log("error 2")
      console.log(error)
    }
  };


  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    showTable: true,
    body: []
  });


  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/api/external`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const getCookie = async (cname) => {
     var name = cname + "=";
     var ca = document.cookie.split(';');
     for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1);
        if(c.indexOf(name) === 0)
           return c.substring(name.length,c.length);
     }
     return "";
}

    useEffect(() => {
      const fetchUserWorkspaces = async () => {

        const payload = {"user_email": user.email.toLowerCase()};
        const urlAuth = `${base_url}${stage}/auth`;

        try{
          const responseDataAuth = await fetch(urlAuth, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': API_KEY,
             },
            body: JSON.stringify(payload),
          });
          if(responseDataAuth.ok) {
            const jsonDataAuth = await responseDataAuth.json();
            setData(jsonDataAuth);
          } else{
          }
          // Fetch data for /workspaces endpoint
          /*
          const responseDataWorkspace = await fetch(urlWorkspace, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          if(responseDataWorkspace.ok) {
            const jsonDataWorkspace = await responseDataWorkspace.json();
            console.log(jsonDataWorkspace);
            setData2(jsonDataWorkspace);
            console.log(data2);
          } else{
            console.error('Error: ' + responseDataWorkspace.status);
          }
          */
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchUserWorkspaces();
      const interval = setInterval(fetchUserWorkspaces, 5000);
      return () => clearInterval(interval);
    }, []);
/*
    if(data2.instances && data2.instances.length > 0){
      instanceId = data2.instances[0].instance_id;
    }
    */


  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  // Function that converts Date format into Eastern Time format
  const convertToEasternTime = (inputDate, date_column_name) => {
    if(date_column_name === "last_launched"){
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      };

      const parsedDate = new Date(inputDate);
      const formattedDate = parsedDate.toLocaleString('en-US', { ...options, timeZone: 'America/New_York' });

      return formattedDate;
    } else if(date_column_name === "project_end_date") {
      // Split the input date into year, month, and day components
      const [year, month, day] = inputDate.split('T')[0].split('-');

      // Create a new Date object using the parsed components
      const parsedDate = new Date(year, parseInt(month) - 1, day);

      // Format the parsed date using toLocaleDateString
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };

      const formattedDate = parsedDate.toLocaleDateString('en-US', options);

      return formattedDate;
    }
  };

  const TableContainer = () => {

    return (
      <Table borderless responsive>
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Project End Date</th>
            <th>Last Launched</th>
            <th>Status</th>
            <th>Workspace Actions</th>
          </tr>
        </thead>
        <tbody>
        {
            data.projects && data.projects.length > 0 ? (
              data.projects.map(item => (
                <tr key={item.project_id}>
                  <td>{item.project_name}</td>
                  <td>{convertToEasternTime(item.project_access_end_date, 'project_end_date')}</td>
                  <td>{item.workspaces.length > 0 ? convertToEasternTime(item.workspaces[0].launched, 'last_launched') : null}</td>
                  <td>{item.workspaces.length > 0 ? item.workspaces[0].instance_status : null}</td>
                  <td>
                    <CreateWorkspace
                      key={item.project_id}
                      rowIndex={item.project_id}
                      /*isOpen={isOpen[item.project_id]}*/
                      handleLaunchClick={handleLaunchClick}
                      handleOpenClick={handleOpenClick}
                      instanceId={item.workspaces.length > 0 ? item.workspaces[0].instance_id : null}
                      dns={item.workspaces.length > 0 ? item.workspaces[0].instance_dns : null}
                      instanceStatus={item.workspaces.length > 0 ? item.workspaces[0].instance_status : null}
                    />
                  </td>
                  <td>
                    <TerminateWorkspace
                      key={item.project_id}
                      rowIndex={item.project_id}
                      instanceId={item.workspaces.length > 0 ? item.workspaces[0].instance_id : null}
                      handleTerminateClick={handleTerminateClick}
                      /*isOpen={isOpen[item.project_id]}*/
                      instanceStatus={item.workspaces.length > 0 ? item.workspaces[0].instance_status : null}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <div className="no-data-message">No Data to display yet...</div>
            )
          }
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}

        {state.showTable && (
            <div>
                <TableContainer />
            </div>
        )}


        {!audience && (
          <Alert color="warning">
            <p>
              You can't call the API at the moment because your application does
              not have any configuration for <code>audience</code>, or it is
              using the default value of <code>YOUR_API_IDENTIFIER</code>. You
              might get this default value if you used the "Download Sample"
              feature of{" "}
              <a href="https://auth0.com/docs/quickstart/spa/react">
                the quickstart guide
              </a>
              , but have not set an API up in your Auth0 Tenant. You can find
              out more information on{" "}
              <a href="https://auth0.com/docs/api">setting up APIs</a> in the
              Auth0 Docs.
            </p>
            <p>
              The audience is the identifier of the API that you want to call
              (see{" "}
              <a href="https://auth0.com/docs/get-started/dashboard/tenant-settings#api-authorization-settings">
                API Authorization Settings
              </a>{" "}
              for more info).
            </p>

            <p>
              In this sample, you can configure the audience in a couple of
              ways:
            </p>
            <ul>
              <li>
                in the <code>src/index.js</code> file
              </li>
              <li>
                by specifying it in the <code>auth_config.json</code> file (see
                the <code>auth_config.json.example</code> file for an example of
                where it should go)
              </li>
            </ul>
            <p>
              Once you have configured the value for <code>audience</code>,
              please restart the app and try to use the "Ping API" button below.
            </p>
          </Alert>
        )}


      </div>

    </>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
