import React, {useState} from 'react';
import { Container, Form, FormGroup, Label, Input, Button} from 'reactstrap';


const ContactForm = () => {
  const [formData, setFormData] = useState ({
    name: '',
    email: '',
    message: '',
  });

  const stage = process.env.REACT_APP_STAGE;
  const base_url = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) =>{
    const API_KEY = process.env.REACT_APP_API_KEY;
    e.preventDefault();
    try{
      const response = await fetch(
        `${base_url}${stage}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': API_KEY,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log(result)
      if (response.ok) {
        alert("email sent successfully!");
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        alert(`Failed to send email ${result.message}`);
      }
    } catch (error) {
      alert(`Error sending email: ${error.message}`);
    }
  };

  return (
    <div>
      <div className="mt-5">
        <h1>Contact Us</h1>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" name="name" id="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required/>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} required/>
          </FormGroup>
          <FormGroup>
            <Label for="message">Message</Label>
            <Input type="textarea" name="message" id="message" placeholder="Write your message here" value={formData.message} onChange={handleChange} required/>
          </FormGroup>
          <Button type="submit" className="submit-button-form">Submit</Button>
        </Form>
      </div>
    </div>
  );

};
export default ContactForm
